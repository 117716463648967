import React,{useContext, useEffect} from 'react';
import { StyleSheet,View,Text } from 'react-native';

import config from '../../config.json';
import { isLoggedInAsync } from '../../lib/helper/AuthHelper';

import AppContext from '../../context/AppContext';

import { Platform } from 'react-native';

const ScrDashboard = ({...props}) =>{
    const appContext = useContext(AppContext);
    useEffect(() => {        
        isLoggedInAsync(appContext);
    }, []);

    return ( 
        <View>
            <Text>{Platform.OS}</Text>
            <Text> This is Dashboard page , Here it check is Previously Logged In </Text>
            <Text> If Logged In Then Stay here </Text>
            <Text> Else </Text>
            <Text style={{color:'green'}} > Go to Login page </Text>
            <Text style={{color:'red'}} onPress={()=>props.navigation.navigate(config.SCREENS.LOGIN)} > Logout </Text>
            <Text style={{color:'blue'}} onPress={()=>{ props.navigation.navigate(config.SCREENS.LOCK);}} > Go to Lock page </Text>
            <Text></Text>
        </View>
    );

}
export default ScrDashboard;