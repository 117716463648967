import React,{Suspense, useContext, useEffect,useState} from 'react';
import 'react-native-gesture-handler';
import { createDrawerNavigator } from '@react-navigation/drawer';


//Config File  //
import config from '../../config.json';
// Screens //
import {ScrLock,ScrProfile} from "../../screens/index";

// Auth helper
import { isLoggedInAsync } from '../../lib/helper/AuthHelper';
// Fetch data from TAB NAVIGATOR //
import TabNavigationStackBottom from './TabNavigationStackBottom';
import { StyleSheet } from 'react-native';

import AppContext from '../../context/AppContext';


// Custom Drawer - Content //
// import CustomDrawer from '../../screens/private/drawer/CustomDrawer'
const CustomDrawer = React.lazy(()=>import('../../screens/private/drawer/CustomDrawer'));


const Drawer = createDrawerNavigator();




// Use navigation //


const DrawerNavigationStack =({...props}) =>{
    const appContext = useContext(AppContext);

    console.log(appContext);

    useEffect(()=>{
            isLoggedInAsync().then(r=>{
                if(r==false){
                    props.navigation.navigate(config.SCREENS.LOGIN)
                    return;
                }
            });
    },[]);
    return(
       

        <Drawer.Navigator initialRouteName={config.SCREENS.LOCK} screenOptions={{
            headerShown:true, 
            headerTintColor: config._STYLES.BAR.bgcolor_top, 
            headerStyle: styles.headerStyle,
            headerTitleAlign:'center',
            headerTintColor:'white',
            drawerActiveBackgroundColor: '#aa18ea',
            drawerActiveTintColor: '#fff',
            drawerInactiveTintColor: '#333',
            headerTitleStyle:styles.headerTitleStyle,
            drawerLabelStyle: {
                marginLeft: -25,
                fontFamily: 'Roboto-Medium',
                fontSize: 15,
              }
        }} drawerContent={props => <Suspense fallback={<div>Loading...</div>}> <CustomDrawer navigation={props.navigation}/> </Suspense>}>
       { /* }} drawerContent={props => <CustomDrawer navigation={props.navigation}/>}> */ }

        <Drawer.Screen name="Home"
        component={TabNavigationStackBottom}
        options={{
          drawerIcon: ({color}) => (
            <Ionicons name="home-outline" size={22} color={color} />
          ),
        }}/>

            <Drawer.Screen name={config.SCREENS.LOCK} options={{title:"Lock",headerShown:false}} component={ScrLock} />
            <Drawer.Screen name={config.SCREENS.PROFILE} options={{title:appContext.authdata.UserData.user_name,headerShown:true}} component={ScrProfile} />

        </Drawer.Navigator>

       
    );
}

export default DrawerNavigationStack;

const styles = StyleSheet.create({
    headerStyle : {
        backgroundColor:config._STYLES.BAR.bgcolor_top,
        height:60
    },
    headerTitleStyle:{
        fontFamily:'Arial',
        fontWeight:'500'
    },
    dtabheader:{
        display:'none'
    }

});