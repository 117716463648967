import React from 'react';
import { StyleSheet,Text } from 'react-native';
import config from '../../config.json';
// Screens  //
import {ScrDashboard,ScrProfile,ScrLock} from '../../screens/index';

import IonIcon from 'react-native-vector-icons/Ionicons';
import SimpleLineIcons from 'react-native-vector-icons/SimpleLineIcons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';



const Tab = createBottomTabNavigator();

const TabNavigationStackBottom = ({...props}) =>{
    return (
            <Tab.Navigator initialRouteName={config.SCREENS.DASHBOARD}    
            screenOptions={{
                headerShown : false,
                tabBarStyle:styles.barStyle
            }}>
            
                
                <Tab.Screen name={config.SCREENS.DASHBOARD} component={ScrDashboard} options={{ 
                    tabBarLabel:"Home" ,
                    tabBarIcon : ({focused})=>(
                        <IonIcon color={focused ? config._STYLES.BAR.selectedTextColor:config._STYLES.BAR.defaultTextColor} name='md-home-outline' size={15} />
                    )
                }}/>
            </Tab.Navigator>
    );
}
export default TabNavigationStackBottom;

const styles = StyleSheet.create({
    barStyle:{
        backgroundColor: config._STYLES.BAR.bgcolor_bottom,
        height:39
    },
    tabBarLabel:{
        color: '#292929',
        fontSize: 12,
    }

  });