import React,{useContext, useEffect} from 'react';
import { StyleSheet,View,Text } from 'react-native';
import config from '../../config.json';

import AppContext from '../../context/AppContext';

const ScrProfile = ({...props}) =>{
    const appContext = useContext(AppContext);

    return ( 
        <View>            
            <Text> Profile Page {appContext.authdata.UserData.user_name} </Text>            
        </View>
    );

}
export default ScrProfile;