import DrawerNavigationStack from './AppStack/DrawerNavigationStack'

const AppNavStack=({...props})=>{
    return (
        <DrawerNavigationStack/>
    );
}

export default AppNavStack;

